import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Products from "../components/product-list/products";
import CategoriesChildrenFilter from "../components/CategoryChildrenFilter";

export default function CategoryChildrenPage({ data, pageContext }) {
  console.log(pageContext);
  return (
    <Layout>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-5 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
          <CategoriesChildrenFilter activeCategory={pageContext.category} />
          <Products data={data.products} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($category: [String]) {
    products: allSanityChildren(
      filter: { category: { elemMatch: { name: { in: $category } } } }
    ) {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
