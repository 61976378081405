import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import useProduct from "../utils/useProduct";

export default function Products({ data }) {
  const { order, addToOrder } = useProduct();
  return (
    <div className="py-5 sm:py-10">
      <h2 className="sr-only">Products</h2>

      <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {data.nodes.map((product) => (
          <div key={product.id}>
            <div className="relative">
              <div className="relative w-full h-72 rounded-lg overflow-hidden">
                <GatsbyImage
                  className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                  image={getImage(product.image.asset)}
                  alt={product.name}
                />
              </div>
              <Link
                to={`/${product.slug.current}`}
                className="absolute top-0 inset-x-0 h-72 rounded-lg p-4 flex items-end justify-end overflow-hidden"
              >
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                />
                <p className="relative text-lg font-semibold text-white">
                  {product.name}
                </p>
              </Link>
            </div>

            <div className="mt-6">
              {order.filter((e) => e.id === product.id).length > 0 ? (
                <button
                  className="inline-flex w-full bg-gray-100 items-center pointer-events-none py-2 px-4 text-sm border border-transparent shadow-sm font-medium rounded-md"
                  type="button"
                >
                  <CheckCircleIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-400"
                    aria-hidden="true"
                  />
                  Добавлено
                </button>
              ) : (
                <button
                  className="text-center w-full bg-yellow-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  type="button"
                  onClick={() =>
                    addToOrder({ id: product.id, name: product.name })
                  }
                >
                  Добавить
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
