import React from "react";
import classNames from "classnames";
import { useStaticQuery, graphql, Link } from "gatsby";
import { countFn } from "./Count";

export default function CategoriesChildrenFilter({ activeCategory }) {
  const { products } = useStaticQuery(graphql`
    query {
      products: allSanityChildren {
        nodes {
          category {
            slug {
              current
            }
            name
            id
          }
        }
      }
    }
  `);

  const productsWithCounts = countFn(products.nodes);

  return (
    <>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          <Link
            to="/kids"
            className="px-3 py-2 font-medium text-sm rounded-md text-gray-500 hover:text-gray-700"
          >
            <span className="name">Все</span>
            <span className="count ml-1">{products.nodes.length}</span>
          </Link>
          {productsWithCounts.map((product) => (
            <Link
              key={product.id}
              to={`/kids/${product.cat.slug.current}`}
              className={classNames(
                product.name === activeCategory
                  ? "bg-yellow-600 text-white"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md"
              )}
            >
              <span>{product.name}</span>
              <span className="ml-1">{product.count}</span>
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
}
